import React, {useEffect, useState} from "react";
import styled, {createGlobalStyle, css} from "styled-components";
import {Link, useNavigate, useParams} from "react-router-dom";
import {RxCross1, RxCrossCircled} from "react-icons/rx";
import {motion} from "framer-motion";
import {RiDeleteBin6Line} from "react-icons/ri";
import {PiImageBrokenThin} from "react-icons/pi";
import {TbPencilMinus} from "react-icons/tb";
import Select from "react-select";
import {authHeader, authHeaderWithoutContentType} from "../_helpers/auth-header";
import {FaCheckCircle} from "react-icons/fa";

function CreateRecipe({addRecipe}){

    const [newIdentifier, setNewIdentifier] = useState(Number.MAX_SAFE_INTEGER);
    const [newStepNumber, setNewStepNumber] = useState(Number.MAX_SAFE_INTEGER);
    const initialRecipeState = {
        titleDutch: '',
        titleEnglish: '',
        descriptionDutch: '',
        descriptionEnglish: '',
        cookingTime: 0,
        originalSource: '',
        tips: '',
        createdBy: '',
        ingredients: [],
        instructions: []
    };
    const initialIngredientState = {
        id: newIdentifier,
        status: "new",
        quantity: 0,
        unit: undefined,
        grocery: undefined,
        preparation: undefined
    };
    const initialInstructionState = {
        id: newIdentifier,
        status: "new",
        stepNumber: newStepNumber,
        descriptionDutch: '',
    };
    const [image, setImage] = useState();
    const [units, setUnits] = useState();
    const [groceries, setGroceries] = useState();
    const [preparations, setPreprations] = useState();
    const [previewImage, setPreviewImage] = useState(null);
    const [recipe, setRecipe] = useState(initialRecipeState);
    const [newIngredient, setNewIngredient] = useState(initialIngredientState);
    const [newInstruction, setNewInstruction] = useState(initialInstructionState);
    const [selectedIngredientToEdit, setSelectedIngredientToEdit] = useState(0);
    let params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        if (params.id !== 'new') {
            getRecipe()
        }
        getUnits();
        getGroceries();
        getPreparations();
    }, [params.id] );

    async function getRecipe() {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION + `/recipe?recipeId=${params.id}`)
        const recipebla = await response.json();
        setRecipe(recipebla)
    }
    async function getUnits() {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION + `/getUnits`);
        const units = await response.json();
        setUnits(units)
    }
    async function getGroceries() {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION + `/getGroceries`);
        const groceries = await response.json();
        setGroceries(groceries)
    }
    async function getPreparations() {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION + `/getPreparations`);
        const preprations = await response.json();
        setPreprations(preprations)
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setRecipe({ ...recipe, [name]: value })
    }

    const handleNewIngredient = (event) => {
        const { name, value } = event.target;
        if (name === 'quantity') {
            setNewIngredient({ ...newIngredient, [name]: Number(value)});
        } else {
            setNewIngredient({ ...newIngredient, [name]: value });
        }
    }
    const handleNewInstruction = (event) => {
        const { name, value } = event.target;
        setNewInstruction({ ...newInstruction, [name]: value });
    }
    const handleEditIngredientField = (ingredientId, fieldName, event) => {
        changeRecipeWithIdWithStatusEdit(recipe.ingredients, ingredientId, fieldName, event)
    }
    const editSaveIngredient = (event) => {
        setSelectedIngredientToEdit(0);
    }
    const editIngredient = (ingredientId) => {
        if (selectedIngredientToEdit === ingredientId) {
            setSelectedIngredientToEdit(0)
        } else {
            setSelectedIngredientToEdit(ingredientId)
        }
    }
    const removeIngredient = (ingredientId) => {
        changeRecipeIngredientsWithId(recipe.ingredients, ingredientId, "status", "delete")
    }
    const removeInstruction = (instructionId) => {
        changeRecipeInstructionssWithId(recipe.instructions, instructionId, "status", "delete")
    }
    function changeRecipeIngredientsWithId(array, id, fieldName, fieldValue) {
        const newArray = array.map(item =>
            item.id === id
                ? {...item, [fieldName]: fieldValue}
                : item
        );
        setRecipe({...recipe, ingredients: newArray});
    }
    function changeRecipeInstructionssWithId(array, id, fieldName, fieldValue) {
        const newArray = array.map(item =>
            item.id === id
                ? {...item, [fieldName]: fieldValue}
                : item
        );
        setRecipe({...recipe, instructions: newArray});
    }
    function changeRecipeWithIdWithStatusEdit(array, id, fieldName, fieldValue) {
        const newArray = array.map(item =>
            item.id === id
                ? {...item, [fieldName]: fieldValue, status: 'edit'}
                : item
        );
        setRecipe({...recipe, ingredients: newArray});
    }
    const getNewIdentifier = () => {
        setNewIdentifier(prevState => prevState -1)
    };

    const handleSubmit  = async (e) => {
        e.preventDefault();
        if (recipe.id !== undefined) {
            const data = new FormData();
            data.append("file", image);
            data.append("recipe", JSON.stringify(recipe));
            const response = await fetch(`${process.env.REACT_APP_BACKEND_LOCATION}/admin/recipe/${recipe.id}`, {
                method: 'PUT',
                headers: authHeaderWithoutContentType(),
                body: data
            });
            if (response.status === 201 || response.status === 200) {
                setRecipe(initialRecipeState);
                navigate(`/recipe/show/${recipe.id}`)
            }
        } else {
            const data = new FormData();
            data.append("recipe", JSON.stringify(recipe));
            const response = await fetch(`${process.env.REACT_APP_BACKEND_LOCATION}/admin/recipe`, {
                method: 'POST',
                headers: authHeader(),
                body: data
            });
            if (response.status === 201 || response.status === 200) {
                setRecipe(initialRecipeState);
                navigate(`/recipe/show/${recipe.id}`)
            }
        }

    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImage(img);
            setPreviewImage(URL.createObjectURL(img));
        }
    };

    const addIngredientLine = (event) => {
        getNewIdentifier();
        recipe.ingredients.push(newIngredient);
        initialIngredientState.id = initialIngredientState.id -1
        setNewIngredient(initialIngredientState);
        console.log("addIngredientLine einde");
        console.log(newIngredient);
    }
    const addInstructionLine = (event) => {
        getNewIdentifier();
        recipe.instructions.push(newInstruction);
        initialInstructionState.id = initialInstructionState.id -1
        setNewInstruction(initialInstructionState);
        console.log("addInstructionLine einde");
        console.log(newInstruction);
    }


    const title = recipe?.id === 'new' ? 'Recept toevoegen' : 'Recept wijzigen';
    const error  = '';
    return (
        <>
        <GlobalStyle />
        <Wrapper
            // animate={{opacity: 1}}
            // initial={{opacity: 0}}
            // exit={{opacity: 0}}
            // transition={{duration: 0.5}}
        >
            <Nav>
                <Logo to={`/recipe/show/${params.id}`}> <RxCross1/></Logo>
            </Nav>
            <StyledFormWrapper>
                <StyledForm onSubmit={(e) => handleSubmit(e)}>
                    <Title><h2>{title}</h2></Title>
                    <Image>
                        {/*{console.log(previewImage)}*/}
                        {/*{console.log(recipe?.imageData)}*/}
                        {(previewImage === null && (recipe === undefined || recipe?.imageData === undefined))
                            ? (
                                // <div></div>
                                <ImagePlaceholder>
                                     <PiImageBrokenThin/>
                                     <p>Voeg foto toe</p>
                                     <input type="file" onChange={onImageChange}/>
                                </ImagePlaceholder>
                            ) : (
                                <NavEditImage>
                                    <img src=
                                        {previewImage
                                            ?
                                            previewImage
                                            :
                                            'data:image/jpeg;base64,' + recipe?.imageData
                                        }
                                        alt={recipe.titleDutch} />
                                    <MainAction>
                                        <Edit> <TbPencilMinus /></Edit>
                                    </MainAction>
                                    <input type="file" onChange={onImageChange}/>
                                </NavEditImage>
                            )
                        }
                    </Image>
                    <label htmlFor="titleDutch">Titel</label>
                    <StyledInput
                        name="titleDutch"
                        type="text"
                        value={recipe?.titleDutch || ''}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter titleDutch"
                    />
                    {/*<input*/}
                    {/*    name= "titleEnglish"*/}
                    {/*    type="text"*/}
                    {/*    value={recipe?.titleEnglish || ''}*/}
                    {/*    onChange={(e) =>handleChange(e)}*/}
                    {/*    placeholder="Enter titleEnglish"*/}
                    {/*/>*/}
                    <label htmlFor="descriptionDutch">Omschrijving</label>
                    <StyledInput
                        name="descriptionDutch"
                        type="text"
                        value={recipe?.descriptionDutch || ''}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter descriptionDutch"
                    />
                    {/*<input*/}
                    {/*    name= "descriptionEnglish"*/}
                    {/*    type="text"*/}
                    {/*    value={recipe?.descriptionEnglish || ''}*/}
                    {/*    onChange={(e) =>handleChange(e)}*/}
                    {/*    placeholder="Enter descriptionEnglish"*/}
                    {/*/>*/}
                    <label htmlFor="cookingTime">Bereidingstijd</label>
                    <StyledInput
                        name="cookingTime"
                        type="number"
                        value={recipe?.cookingTime || 0}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter cookingTime"
                    />
                    <label htmlFor="originalSource">Bronvermelding</label>
                    <StyledInput
                        name="originalSource"
                        type="text"
                        value={recipe?.originalSource || ''}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter originalSource"
                    />
                    <label htmlFor="tips">Tips</label>
                    <StyledInput
                        name="tips"
                        type="text"
                        value={recipe?.tips || ''}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter tips"
                    />
                    <label htmlFor="createdBy">Gemaakt door</label>
                    <StyledInput
                        name="createdBy"
                        type="text"
                        value={recipe?.createdBy || ''}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter createdBy"
                    />
                    {error && (
                        <StyledError>Error message here</StyledError>
                    )}
                    <StyledButton type="submit">Opslaan</StyledButton>
                </StyledForm>
            </StyledFormWrapper>
            <StyledFormWrapper>
                <div>
                    <h2>Ingredienten</h2>
                    {recipe?.ingredients?.map((ingredient) => {
                        return (
                            <IngredientList2 key={ingredient?.id}>
                                {selectedIngredientToEdit === ingredient.id
                                    ?
                                    <IngredientList>
                                        <StyledInput
                                            name="quantity"
                                            type="number"
                                            value={ingredient.quantity}
                                            onChange={(e) => handleEditIngredientField(ingredient.id, 'quantity', e.target.value)}
                                        />
                                        <StyledSelect
                                            name="unit"
                                            styles={{
                                                indicatorSeparator: () => {
                                                }, // removes the "stick"
                                                dropdownIndicator: () => {
                                                },
                                                control: (base, state) => ({
                                                    ...base,
                                                    minHeight: '30px',
                                                    height: '30px',
                                                })
                                            }}
                                            isSearchable
                                            value={ingredient.unit}
                                            options={units}
                                            getOptionLabel={unit => unit.nameUnitDutch}
                                            // getOptionValue={unit => unit}
                                            onChange={(e) => handleEditIngredientField(ingredient.id, 'unit', e)}
                                        />
                                        <StyledSelect
                                            styles={{
                                                indicatorSeparator: () => {
                                                }, // removes the "stick"
                                                dropdownIndicator: () => {
                                                },
                                                control: (base, state) => ({
                                                    ...base,
                                                    minHeight: '30px',
                                                    height: '30px',
                                                })
                                            }}
                                            isSearchable
                                            value={ingredient.grocery}
                                            options={groceries}
                                            getOptionLabel={grocery => grocery.nameGroceryDutch}
                                            getOptionValue={grocery => grocery}
                                            onChange={(e) => handleEditIngredientField(ingredient.id, 'grocery', e)}
                                        />
                                    </IngredientList>
                                    :
                                    <div className={ingredient.status}>
                                        {ingredient?.quantity} {ingredient?.unit?.abbreviationDutch !== null ? ingredient.unit?.abbreviationDutch : (ingredient.quantity > 1 ? ingredient.unit.nameUnitDutchPlural : ingredient.unit.nameUnitDutch)} {ingredient?.grocery?.nameGroceryDutch}
                                    </div>
                                }
                                {selectedIngredientToEdit === ingredient.id && (
                                    <IngredientListButtons>
                                        <StyledButtonSave onClick={(e) => editSaveIngredient(e)}><FaCheckCircle/></StyledButtonSave>
                                        <StyledButtonCancel onClick={(e) => editSaveIngredient(e)}><RxCrossCircled/></StyledButtonCancel>
                                        <StyledButtonRemove onClick={(e) => removeIngredient(ingredient.id)}><RiDeleteBin6Line/></StyledButtonRemove>
                                    </IngredientListButtons>
                                )}
                                {selectedIngredientToEdit !== ingredient.id && ingredient.status === 'delete' && (
                                    <IngredientListButtons></IngredientListButtons>
                                )}
                                {selectedIngredientToEdit !== ingredient.id && ingredient.status !== 'delete' && (
                                    <IngredientListButtons>
                                        <StyledButtonEdit onClick={(e) => editIngredient(ingredient.id)}><TbPencilMinus/></StyledButtonEdit>
                                        <StyledButtonRemove onClick={(e) => removeIngredient(ingredient.id)}><RiDeleteBin6Line/></StyledButtonRemove>
                                    </IngredientListButtons>
                                )}
                                {}
                                {ingredient.preparation?.namePreparationDutch && selectedIngredientToEdit === 0 && (
                                    <div className={ingredient.status}>
                                        ({ingredient.preparation.namePreparationDutch})
                                    </div>
                                )}
                                {ingredient.preparation !== null && selectedIngredientToEdit === ingredient.id && (
                                    <StyledSelect
                                        name="preparation"
                                                styles={{
                                                    indicatorSeparator: () => {
                                                    }, // removes the "stick"
                                                    dropdownIndicator: () => {
                                                    },
                                                    control: (base, state) => ({
                                                        ...base,
                                                        minHeight: '30px',
                                                        height: '30px',
                                                    })
                                                }}
                                                isSearchable
                                                value={ingredient.preparation}
                                                options={preparations}
                                                getOptionLabel={preparation => preparation.namePreparationDutch}
                                                onChange={(e) => handleEditIngredientField(ingredient.id, 'preparation', e)}
                                            />
                                )}
                                {ingredient.preparation === null && selectedIngredientToEdit === ingredient.id && (
                                    <StyledSelect
                                        name="preparation"
                                        styles={{
                                            indicatorSeparator: () => {
                                            }, // removes the "stick"
                                            dropdownIndicator: () => {
                                            },
                                            control: (base, state) => ({
                                                ...base,
                                                minHeight: '30px',
                                                height: '30px',
                                            })
                                        }}
                                        isSearchable
                                        value={ingredient.preparation}
                                        options={preparations}
                                        getOptionLabel={preparation => preparation.namePreparationDutch}
                                        onChange={(e) => handleEditIngredientField(ingredient.id, 'preparation', e)}
                                    />
                                )}
                            </IngredientList2>
                        )
                    })}
                    <IngredientList2>
                        <IngredientList>
                            <StyledInput
                                name="quantity"
                                type="number"
                                value={newIngredient.quantity || ''}
                                onChange={(e) => handleNewIngredient(e)}
                                placeholder=""
                            />
                            <StyledSelect
                                styles={{
                                    indicatorSeparator: () => {}, // removes the "stick"
                                    dropdownIndicator: () => {},
                                    control: (base, state) => ({
                                        ...base,
                                        minHeight: '30px',
                                        height: '30px',
                                    })
                                }}
                                placeholder="Eenheid"
                                isSearchable
                                value={newIngredient.unit || ''}
                                options={units}
                                getOptionLabel={unit => unit.nameUnitDutch}
                                getOptionValue={unit => unit}
                                onChange={(e) => setNewIngredient({ ...newIngredient, unit: e})}
                            />
                            <StyledSelect
                                styles={{
                                    indicatorSeparator: () => {}, // removes the "stick"
                                    dropdownIndicator: () => {},
                                    control: (base, state) => ({
                                        ...base,
                                        minHeight: '30px',
                                        height: '30px',
                                    })
                                }}
                                isSearchable
                                placeholder="Boodschap"
                                value={newIngredient.grocery}
                                options={groceries}
                                getOptionLabel={grocery => grocery.nameGroceryDutch}
                                getOptionValue={grocery => grocery}
                                onChange={(e) => setNewIngredient({ ...newIngredient, grocery: e})}
                            />
                            {/*<StyledButtonPlus onClick={(e) => addIngredientLine(e)}><IoAddOutline/></StyledButtonPlus>*/}
                        </IngredientList>
                        <IngredientListButtons>
                                <StyledButtonSave onClick={(e) => addIngredientLine(e)}><FaCheckCircle/></StyledButtonSave>
                        </IngredientListButtons>
                        <StyledSelect
                            name="preparation"
                            styles={{
                                indicatorSeparator: () => {
                                }, // removes the "stick"
                                dropdownIndicator: () => {
                                },
                                control: (base, state) => ({
                                    ...base,
                                    minHeight: '30px',
                                    height: '30px',
                                })
                            }}
                            isSearchable
                            value={newIngredient.preparation}
                            options={preparations}
                            placeholder="Voorbereiding"
                            getOptionLabel={preparation => preparation.namePreparationDutch}
                            onChange={(e) => setNewIngredient({ ...newIngredient, preparation: e})}
                        />
                    </IngredientList2>
                    <Instructions>
                        <h2>Bereiding</h2>
                        {recipe?.instructions?.map((instruction) => {
                            return (
                                <InstructionList key={instruction.id}>
                                        <div className={instruction.status}>{instruction.descriptionDutch}</div>
                                    {instruction.status !== 'delete' && (
                                        <StyledButtonRemove onClick={(e) => removeInstruction(instruction.id)}><RiDeleteBin6Line/></StyledButtonRemove>
                                    )}
                                </InstructionList>
                            )
                        })}
                        <InstructionList>
                            <StyledInput
                                name="descriptionDutch"
                                type="text"
                                value={newIngredient.descriptionDutch}
                                onChange={(e) => handleNewInstruction(e)}
                                placeholder="Bereiding"
                            />
                            {/*<StyledButtonPlus><IoAddOutline/></StyledButtonPlus>*/}
                            <IngredientListButtons>
                                <StyledButtonSave onClick={(e) => addInstructionLine(e)}><FaCheckCircle/></StyledButtonSave>
                            </IngredientListButtons>

                        </InstructionList>
                    </Instructions>
                </div>
            </StyledFormWrapper>
        </Wrapper>
        </>
    )
}

const GlobalStyle = createGlobalStyle`
`;

const SharedStyles = css`
    background-color: white; //lightgrey
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
`;

const Title = styled.div`
    text-align: center;
`
const StyledFormWrapper = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0 20px;
    label{
        font-weight: bold;
    }
`

const StyledForm = styled.form`
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    box-sizing: border-box;
`

const StyledInput = styled.input`
    display: block;
    width: 100%;
    ${SharedStyles}
`

const StyledSelect = styled(Select)`
    display: block;
    width: 100%;
    ${SharedStyles}
    react-select__indicators { display: none;}
`

const StyledButton = styled.button`
    display: block;
    background-color: var(--main-accent-color);
    color: white;
    font-size: 0.9rem;
    border: 0;
    border-radius: 5px;
    height: 30px;
    padding: 0 20px;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 1rem;
`

const StyledButtonPlus = styled.button`
    background-color: var(--main-accent-color);
    color: white;
    border: 0;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 0 2px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 15px 0 15px 0;
`
const StyledButtonSave = styled.button`
    color: green;
    background-color: transparent;
    border: 0;
    //border-radius: 50%;
    svg {
        font-size: 1rem;
    }
    padding: 2px;
    cursor: pointer;
    box-sizing: border-box;
`
const StyledButtonCancel = styled.button`
    padding: 2px;
    color: red;
    background-color: transparent;
    border: 0;
    //border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
    svg {
        font-size: 1.1rem;
    }
`
const StyledButtonEdit = styled.button`
    padding: 2px;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
    svg {
        font-size: 1rem;
    }
`
const StyledButtonRemove = styled.button`
    padding: 2px;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
    svg {
        font-size: 1rem;
    }
`

const StyledError = styled.div`
    color: red;
    font-weight: 800;
    margin: 0 0 40px 0;
`
const Nav = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    background: rgb(250, 237, 247);
    margin-left: 1rem;
    margin-top: 1rem;

    svg {
        font-size: 2rem;
    }
`
const NavEditImage = styled.div`
    width: 100%;
    height: 15rem;
    //background-color: #f3f2f2;
    background-color: white;
    text-align: center;
    vertical-align: center;
    color: grey;
    border-radius: 2rem;
    //padding-top: 4rem;
    //border: 1px solid #ddd;
    //border: 1px solid grey;
    //box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    svg{
        font-size: 4rem;
    }
`
const Logo = styled(Link)`
    text-align: center;
    color: black;
`
const Wrapper = styled.div`
    //font-size: 0.8rem;
`
const ImagePlaceholder = styled.div`
    width: 100%;
    height: 11rem;
    border-radius: 2rem;
    //background-color: #f3f2f2;
    background-color: white;
    text-align: center;
    vertical-align: center;
    color: grey;
    padding-top: 4rem;
    //border: 1px solid #ddd;
    
    //box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    svg{
        font-size: 4rem;
    }
`

const Image = styled(motion.div)`
    display: block;
    position: relative;
    height: 100%;
    //background: white;
    align-items: center;
    svg{
        font-size: 1.5rem;
    }
    bottom: 1rem;
    input {
        display: block;
        height: 15rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
    }
   
    img {
        width: 100%;
        border-radius: 2rem;
        object-fit: cover;
        aspect-ratio: 4/3;
        display: block;
        height: 100%;
        //top: 0;
    }
`

const MainAction = styled.div`
    border-radius: 50%;
    background-color: var(--main-accent-color);
    display: flex;
    right: 1rem;
    bottom: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    font-weight: bold;
    font-size: 1rem;
    position: absolute;
    align-items: center;
`

const Edit = styled(Link)`
    align-content: center;
    color: white;
    svg {
        font-size: 1.5rem;
    }
`
const IngredientList = styled.div`
    list-style: none;
    //padding: 0;
    display: grid;
    grid-template-columns: 1fr 4fr 4fr;
    //border: 1px solid black;
    margin-bottom: 0.3rem;
`
const IngredientList2 = styled.div`
    list-style: none;
    //padding: 0;
    display: grid;
    grid-template-columns: 9fr 2fr;
    //border: 1px solid purple;
    margin-bottom: 0.3rem;
    .new{
        font-style: italic;
        color: grey;
    }    
    .edit{
        font-style: italic;
        color: grey;
    }
    .delete{
        text-decoration: line-through;
        color: darkred;
    }
`
const IngredientListButtons = styled.div`
    text-align: right;
`
const InstructionListButtons = styled.div`
    text-align: right;
`
const InstructionList = styled.div`
    list-style: none;
    //padding: 0;
    display: grid;
    width: 100%;
    grid-template-columns: 7fr 0.5fr;
    //border: 1px solid black;
    margin-bottom: 0.3rem;
    .new{
        font-style: italic;
        color: grey;
    }
    .edit{
        font-style: italic;
        color: grey;
    }
    .delete{
        text-decoration: line-through;
        color: darkred;
    }
`
const Instructions = styled.div`
`

export default CreateRecipe