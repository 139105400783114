import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MyActions from "../components/MyActions";

function Profile() {
    const [user, setUser] = useState([]);
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')));
    }, []);

    return (
        <Wrapper>
            <Content>
            {/*<Content className="col-md-6 col-md-offset-3">*/}
                {user && (
                    <div>
                        <h1>Hi {user.firstName}!</h1>
                        <p>Welkom bij Culinell</p>
                        <Link to="/login">Logout</Link>
                    </div>
                )}
                {!user && (
                    <div>
                        <p>Om gebruik te maken van de extra functionaliteit is het nodig om in te loggen.</p>
                        <Link to="/login">Login</Link>
                    </div>
                )}
                <Link to="/">Back to Home</Link>
            </Content>
            <Buffer></Buffer>
            <MyActions />
        </Wrapper>
    )
};

const Content = styled.div`
    width: 90%;
    justify-items: center;
    margin: auto;
`

// const Content = styled.div`
//     width: 90%;
//     justify-items: center;
//     margin: auto;
// `
const Wrapper = styled.div`
`
const Buffer = styled.div`
    height: 80px;
`
export default Profile