export const userService = {
    login,
    logout,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            contentType: 'application/json',
            authorization: 'Basic ' + btoa(`${username}:${password}`)
        },
    };
    return fetch(`${process.env.REACT_APP_BACKEND_LOCATION}/user/login`, requestOptions)
        .then(handleResponse)
        .then(users => {
            console.log(users.email)
            if (users && users.email === username) {
                users.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('user', JSON.stringify(users));
            }
            return users;
        });
}

function logout() {
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}