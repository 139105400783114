import React from 'react';
import {userService} from "../services/user.service";
import styled from "styled-components";
import MyActions from "../components/MyActions";
import {FaLock, FaUser} from "react-icons/fa";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCancel(e) {
        e.preventDefault()
        document.location = "/"
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;

        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true });
        userService.login(username, password)
            .then(
                user => {
                    // console.log(user)
                    // this.props.navigation.navigate('/')
                    document.location = "/"
                },
                error => {
                    console.log(error)
                    this.setState({ error, loading: false })
                }
            );
    }

    render() {
        const { username, password, submitted, loading, error } = this.state;
        return (
            <Wrapper>
                <Content>
                    <h2>Login</h2>
                    {error &&
                        <StyledError className={'alert alert-danger'}>{error}</StyledError>
                    }
                    <StyledForm name="form" onSubmit={this.handleSubmit}>
                        <StyledFormField className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                            <FaUser/>
                            <StyledInput placeholder="Email" type="text" className="form-control" name="username" value={username} onChange={this.handleChange} required/>
                            {submitted && !username &&
                                <div className="help-block">Username is required</div>
                            }
                        </StyledFormField>
                        <StyledFormField className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <FaLock/>
                            <StyledInput placeholder="Password" type="password" className="form-control" name="password" value={password} onChange={this.handleChange} required/>
                            {submitted && !password &&
                                <div className="help-block">Password is required</div>
                            }
                        </StyledFormField>
                        <Actions className="form-group">
                            <StyledButton className="btn btn-primary" disabled={loading}>Login</StyledButton>
                            <StyledButton onClick={this.handleCancel}>Cancel</StyledButton>
                        </Actions>

                    </StyledForm>
                </Content>
                <Buffer></Buffer>
                <MyActions />
            </Wrapper>
        );
    }
}

const Content = styled.div`
    width: 90%;
    justify-items: center;
    margin: auto;
`

const StyledForm = styled.form`
    width: 100%;
    max-width: 700px;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
`

const Actions = styled.div`
    display: flex;
    justify-content:space-evenly;
    justify-items: center;
    margin-bottom: 1rem;
    .active {
        background: white;
       color: rgb(198, 30, 156);
    }
`

const StyledInput = styled.input`
    //display: block;
    width: 100%;
    background-color: white; //lightgrey
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin: 10px 0 20px 0;
    box-sizing: border-box;
    padding-left: 30px;
    color: rgb(198, 30, 156);
    
`
const StyledFormField = styled.div`
    svg {
        position: absolute;
        translate: 10px 22px;
        color: rgb(198, 30, 156);
    }
    input::placeholder {
        color: rgb(198, 30, 156);
    }
`

const StyledButton = styled.button`
    display: block;
    background-color: rgb(198, 30, 156);
    color: white;
    font-size: 0.9rem;
    border: 0;
    border-radius: 5px;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
    box-sizing: border-box;
`
const StyledError = styled.div`
    color: red;
    font-weight: 800;
    margin: 0 0 40px 0;
`

const Wrapper = styled.div`
`
const Buffer = styled.div`
    height: 80px;
`
export { LoginPage };