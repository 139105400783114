import React, {useEffect, useState} from 'react'
import { Chart } from "react-google-charts";

const Histogram = ({ data }) => {

    const options = {
        // title: "Recipes with their calories",
        legend: { position: "none" },
        vAxis: {
            textPosition: 'out',
            gridlines: {
                color: 'transparent',
                // count: 0,
                // interval: 0
            },
        },
        hAxis: {
            textPosition: "out"
        },
        bar: { gap: 0},
        colors: ['rgb(198, 30, 156)'],
        // hAxis: { textPosition: 'none'},
        chartArea: {
            left: 20,
            right: 20,
            width: "100%"
        },
        enableInteractivity: false,
        histogram: {
            hideBucketItems: true,

        },
        backgroundColor: 'rgb(250, 237, 247)',

    };

    return (
            <Chart
                chartType="Histogram"
                width="100%"
                height="200px"
                data={data}
                options={options}
            />
    );
}


export default Histogram;